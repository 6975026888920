import '@hotwired/turbo';

import 'lib/sentry';

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

//import { MenuController } from '@publicistco/markup-kit/dist-src';

const application = Application.start();
//const context = require.context("controllers/public", true, /\.js$/);
const commonContext = require.context("controllers/common", true, /\.js$/);

//application.load(definitionsFromContext(context));
application.load(definitionsFromContext(commonContext));
//application.register('menu', MenuController);
application.__debug = !!document.documentElement.dataset.debug;
application.stimulusUseDebug = application.__debug;
